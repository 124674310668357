var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('page-title',{attrs:{"title":"配送資訊"}}),_c('div',{staticClass:"mb-5"},[_c('h3',{staticClass:"mb-4 mt-3 heading-h3"},[_vm._v("一、運送說明")]),_c('p',[_vm._v(" 請設定基本運費規則，作為合作店家訂購時之參考，實際運費保留供應商調整之權。"),_c('br'),_vm._v(" （若無提供特定配送方式/地區/免運優惠，則該欄位留白） ")])]),_c('v-form',[_c('div',{staticClass:"mb-10"},_vm._l((['main_island', 'outlying_islands']),function(row,rowIndex){return _c('v-row',{key:rowIndex,staticClass:"row-wrapper mb-6 mx-0"},[_c('v-col',{staticClass:"d-flex flex-column",style:({ gap: '20px' }),attrs:{"cols":"12"}},_vm._l((_vm.temperature),function(col,colIndex){return _c('div',{key:(rowIndex + "-" + colIndex),attrs:{"cols":"8"}},[(col === 'normal')?_c('span',[_vm._v(_vm._s(row === 'main_island' ? '本島運費' : '離島運費'))]):_vm._e(),_c('div',{staticClass:"d-flex align-center",style:({ gap: '30px' })},[_c('number-input',{attrs:{"label":{
                  normal: '常溫',
                  refrigeration: '冷藏',
                  freezing: '冷凍',
                }[col],"control":false},on:{"input":function () { return _vm.handleInput(row, col); }},model:{value:(_vm.numbers[(row + "-" + col)].amount),callback:function ($$v) {_vm.$set(_vm.numbers[(row + "-" + col)], "amount", $$v)},expression:"numbers[`${row}-${col}`].amount"}}),_c('span',[_vm._v("元")])],1)])}),0),_c('v-col',{staticClass:"d-flex flex-column",style:({ gap: '20px' }),attrs:{"cols":"12"}},_vm._l((_vm.temperature),function(col,colIndex){return _c('div',{key:(rowIndex + "-" + colIndex),attrs:{"cols":"8"}},[(col === 'normal')?_c('span',[_vm._v(_vm._s(row === 'main_island' ? '本島免運門檻' : '離島免運門檻'))]):_vm._e(),_c('div',{staticClass:"d-flex align-center",style:({ gap: '30px' })},[_c('number-input',{attrs:{"label":{
                  normal: '常溫',
                  refrigeration: '冷藏',
                  freezing: '冷凍',
                }[col],"control":false},on:{"input":function () { return _vm.handleInput(row === 'main_island' ? 'main_island_free' : 'outlying_islands_free', col); }},model:{value:(_vm.numbers[((row === 'main_island' ? 'main_island_free' : 'outlying_islands_free') + "-" + col)].amount),callback:function ($$v) {_vm.$set(_vm.numbers[((row === 'main_island' ? 'main_island_free' : 'outlying_islands_free') + "-" + col)], "amount", $$v)},expression:"numbers[`${row === 'main_island' ? 'main_island_free' : 'outlying_islands_free'}-${col}`].amount"}}),_c('span',[_vm._v("元")])],1)])}),0)],1)}),1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }